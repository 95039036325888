<template>
  <div class="new-treatment flex-column--align-center">
    <modal-header current-modal-name="new-treatment-modal">
      <span slot="header-title">{{ $t('dashboard.label.newTreatment') }}</span>
    </modal-header>
    <main class="new-treatment__options flex-column--align-center">
      <new-treatment-option
        icon="icon-photo-analysis"
        :button-label="$t('dashboard.newTreatment.action.treatmentFromPhotoAnalysis')"
        :hint="$t('dashboard.label.photoAnalysisDescription')"
        :routing-info="$options.newTreatmentRoutingInfo.TREATMENT_FROM_PHOTO_ANALYSIS"
      />
      <new-treatment-option
        icon="icon-treatment-with-treatment-directory"
        :button-label="$t('dashboard.newTreatment.action.treatmentFromDirectory')"
        :hint="$t('dashboard.treatmentDirectory.label.createPersonalized')"
        :routing-info="$options.newTreatmentRoutingInfo.TREATMENT_FROM_TREATMENT_DIRECTORY"
      />
      <new-treatment-option
        icon="icon-quick-treatment"
        :button-label="$t('dashboard.newTreatment.action.treatmentFromScratch')"
        :hint="$t('dashboard.label.treatmentFromScratchDescription')"
        :routing-info="$options.newTreatmentRoutingInfo.TREATMENT_FROM_SCRATCH"
      />
    </main>
  </div>
</template>

<script>
import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import NewTreatmentOption from '@/modules/dashboard/components/dashboard/create-treatment/new-treatment/NewTreatmentOption';
import RoutingInfo from '@/modules/dashboard/components/common/RoutingInfo';

import { NEW_TREATMENT_FLOW_TYPES } from '@/modules/dashboard/api/constants';

const NEW_TREATMENT_PATIENT_DETAILS_MODAL_NAME = 'new-treatment-patient-details-modal';
const NEW_TREATMENT_PATIENT_DETAILS_ROUTE_NAME = 'NewTreatmentPatientDetails';

const NEW_TREATMENT_ROUTING_INFO = {
  TREATMENT_FROM_SCRATCH: new RoutingInfo(
    NEW_TREATMENT_PATIENT_DETAILS_MODAL_NAME,
    NEW_TREATMENT_PATIENT_DETAILS_ROUTE_NAME,
    { newTreatmentFlowType: NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_SCRATCH }
  ),
  TREATMENT_FROM_PHOTO_ANALYSIS: new RoutingInfo(
    NEW_TREATMENT_PATIENT_DETAILS_MODAL_NAME,
    NEW_TREATMENT_PATIENT_DETAILS_ROUTE_NAME,
    { newTreatmentFlowType: NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_PHOTO_ANALYSIS }
  ),
  TREATMENT_FROM_TREATMENT_DIRECTORY: new RoutingInfo(
    NEW_TREATMENT_PATIENT_DETAILS_MODAL_NAME,
    NEW_TREATMENT_PATIENT_DETAILS_ROUTE_NAME,
    { newTreatmentFlowType: NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_TREATMENT_DIRECTORY }
  )
};

export default {
  name: 'NewTreatment',
  components: { NewTreatmentOption, ModalHeader },
  newTreatmentRoutingInfo: NEW_TREATMENT_ROUTING_INFO
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/helpers/colors';

.new-treatment {
  &__options {
    margin: 65px 0 40px 0;
  }
}

@media (max-width: 767px) {
  .new-treatment {
    background-color: $white;
    min-height: 100vh;

    &__options {
      margin-top: 60px;
    }
  }
}
</style>
