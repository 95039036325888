<template>
  <section class="new-treatment-option flex--align-center">
    <div class="custom-icon otherXS" :class="icon"></div>
    <div class="new-treatment-option__description option-description flex">
      <button class="rounded-button-white" @click="showModal">
        {{ buttonLabel }}
      </button>
      <span class="option-description__hint">
        {{ hint }}
      </span>
    </div>
  </section>
</template>

<script>
import RoutingInfo from '@/modules/dashboard/components/common/RoutingInfo';

import { handleRoutingInfo } from '@/modules/dashboard/utils';

const DEFAULT_NEW_TREATMENT_OPTION_ROUTE = 'NewTreatment';

export default {
  name: 'NewTreatmentOption',
  props: {
    icon: {
      type: String,
      required: true
    },
    buttonLabel: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      default: ''
    },
    routingInfo: {
      type: RoutingInfo,
      default: () => new RoutingInfo('new-treatment-modal', DEFAULT_NEW_TREATMENT_OPTION_ROUTE)
    }
  },
  methods: {
    showModal() {
      this.$emit('select-option');

      if (this.routingInfo.route !== DEFAULT_NEW_TREATMENT_OPTION_ROUTE) {
        handleRoutingInfo(this.routingInfo, this.$router, this.$modal);
        this.$modal.hide('new-treatment-modal');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/helpers/colors';

.rounded-button-white {
  font-size: 15px;
  line-height: 17px;
}

.new-treatment-option {
  width: 580px;
  margin-bottom: 60px;

  &__description {
    flex-wrap: wrap;
    margin: 15px 0 0 10px;
  }
}

.option-description {
  &__hint {
    margin-top: 15px;

    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;

    color: $grey-color;
  }
}

@media (max-width: 767px) {
  .rounded-button-white {
    font-size: 13px;
    line-height: 15px;
  }

  .new-treatment-option {
    width: auto;
    padding: 0 27px;
    margin-bottom: 40px;
    text-align: center;

    &__description {
      justify-content: center;
      margin: 0;
    }
  }
}
</style>
